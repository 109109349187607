import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components"

const ScanImage = styled.img`
    border: 5px solid ${props => {
        return props.$status ? props.theme.colors.clean : props.theme.colors.risk
    }};
    width: 100%;
    height: auto
`

const ImageContainer = styled.div`
    max-width: ${props => props.$maxWidth}px
`


const NoImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: ${props => props.$maxWidth}px;
    height: ${props => {
        return (props.$maxWidth / 100 * 77) + 'px'
    }};
    border: 5px solid ${props => {
        switch (props.$status) {
            case 'clean':
                return props.theme.colors.clean
            case 'risk':
            case 'warn':
                return props.theme.colors.risk
            default:
                return props.theme.colors.warn
        }
    }};
`

export const Screenshot = ({imageData, status, maxWidth, ...props}) => {
    if (imageData) {
        return (
            <ImageContainer $maxWidth={maxWidth}>
                <ScanImage src={`data:image/png;base64,${imageData}`} $status={status} {...props}/>
            </ImageContainer>
        )
    } else {
        return <NoImage $status={status} $maxWidth={maxWidth}>SCREENSHOT UNAVAILABLE</NoImage>
    }
}

NoImage.propTypes = {
    /**
     * The status of the source page
     */
    $status: PropTypes.string,
    /**
     * The maximum width of the image container
     */
    maxWidth: PropTypes.number
}
Screenshot.propTypes = {
    /**
     * The base64 hash of the screenshot
     */
    imageData: PropTypes.string,
    /**
     * Whether the source page is clean or not
     */
    status: PropTypes.string,
    /**
     * The maximum width of the image container
     */
    maxWidth: PropTypes.number
}

Screenshot.defaultProps = {
    imageData: '',
    status: 'clean',
    maxWidth: 500
}
